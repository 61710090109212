'use strict';

const devConfig = require('./dev');
const prodConfig = require('./prod');

module.exports = function(){
    console.log('env', process.env.NODE_ENV);
    switch(process.env.NODE_ENV){
        case 'development':
            console.log('hello dev');

            return devConfig;

        case 'production':
            console.log('hello prod');
            return prodConfig;

        default:
            console.log('hello default');

            return devConfig;
    }
}();