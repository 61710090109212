import router from '../router'
const axios = require('axios')

const API = axios.create({});

API.interceptors.response.use(null, function (error) {
  let errorResponse;
  if (error.response && error.response.data) {
    errorResponse = error.response.data;
    if (error.response.status === 401 && router.history.current.path !== '/pages/login' ) {
      router.push('/pages/login');
      localStorage.removeItem('user');
      localStorage.removeItem('token');
    }
  } else if (error.request) {
    errorResponse = error.request.message || error.request.statusText;
  } else {
    errorResponse = error.message;
  }
  errorResponse = errorResponse ? errorResponse: 'Network error occurred';

  return Promise.reject(errorResponse);
})

export default API;
